var $Banner = $('.banner-container'), bannerSet = false;
var bannerBackstretch = function () {
    if ($Banner.length) {
        if (Modernizr.mq('(min-width: 992px)') && (bannerSet === false)) {
            // Access the instance
            $Banner.backstretch($Banner.data('image'));
            $(window).on("backstretch.after", function (e, instance, index) {
                bannerSet = true;
            });
        } else if (Modernizr.mq('(max-width: 991px)') && (bannerSet === true)) {
            $('.banner-container .backstretch').remove();
            bannerSet = false;
        }
    }
};